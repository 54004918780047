<template>
  <body>
    <div class="mt-5"></div>
    <div class="privacy-section container">
      <div class="privacy">
        <div class="privacy">
          <div class="detail">
            <h1>Privacy Statement</h1>
            <pre>
						This Privacy Statement is effective as of 13 August 2021.

At Hayche, we acknowledge the importance of protecting your personal information and are committed to processing it responsibly and in compliance with applicable data protection laws in all countries in which Hayche operates. 

This Privacy Statement describes Hayche general privacy practices that apply to the personal information we collect, use, and share about our clients, business partners, suppliers, and other organizations with which hayche has or contemplates a business relationship and the individuals working for them. This Privacy Statement does not apply to the extent Hayche processes personal information on behalf of clients for their benefit and under their control (as a "processor"), such as Cloud Services or applications ("apps"). It may apply to the collection of information related to authorized users of such services to the extentHayche processes this information for its own interests (as "controller").
	
Why and how we collect and use your personal information 

We may collect your personal information as an individual for various purposes, such as the following:
	Access and use of websites or another online service (including "apps")
When entering one of our websites, or using an online service (where references to online services include desktop or mobile applications or "apps"), we will record information necessary to provide you with access, for the operation of the website and for us to comply with security and legal requirements about operating our sites, such as passwords, IP address and browser settings. We also collect information about your activities during your visit to personalize your website experiences, such as recording your preferences and settings and collecting statistics to help us improve and further develop our websites, products, and services.
	
	
Responding to your request for information, order, or support:
When you contact us (online or offline) in connection with a request for information, to order a product or service, to support you, or to participate in a forum or other social computing tool, we collect information necessary to fulfill your request, to grant you access to the product or service, to support you and to be able to contact you. For instance, we collect your name and contact information, details about your request and your agreement with us, and the fulfillment, delivery, and invoicing of your order and we may include client satisfaction survey information. We retain such information for administrative purposes, defending our rights, and in connection with our relationship with you.
	
When you provide your name and contact information to register in connection with such a request, the registration may serve to identify you when you visit our websites. For ordering most services and products, we require you to have registered on the platform Registration may also allow you to customize and control your privacy settings.
	
	
Your use of Hayche Cloud services:
We collect information about your use of Hayche Cloud services to enable product features to operate, improve your user experience, tailor our interactions with you, inform our clients on the overall use of the services, provide support and improve and develop our products and services. 
	
Visitor information:
We register individuals visiting our sites and locations (name, identification, and business contact information) and use camera supervision for reasons of security and safety of persons and belongings, as well as for regulatory purposes. 
	
	
Marketing
Most information we collect about you comes from our direct interactions with you. When you register for an event we may collect information (online or offline) with the event organization, and during an event, such as participation in sessions and survey results. We combine the personal information we collect to develop aggregate analysis and business intelligence for conducting our business and for marketing purposes. You can choose to receive information by email, telephone, or postal mail about our products and services. When visiting our websites or using our services we may provide you with personalized information. You can always opt-out from receiving personalized information from your settings. For subscriptions, please follow the unsubscribe instructions provided at the bottom of each email.
	
Where we reference that we use your personal information in connection with a request, order, transaction, or agreement (or preparing for the same), or to provide you with services that you requested (such as a website), we do this because it is necessary for the performance of an agreement with you.

	
Sharing of Personal Information
As a global organization offering a wide range of products and services, with business processes, management structures, and technical systems that cross borders, Hayche has implemented global policies, along with standards and procedures, for the consistent protection of personal information. As a global company, we may share information about you with our subsidiaries worldwide and transfer it to countries in the world where we do business per this Privacy Statement. 

Where appropriate, Hayche may also share your personal information with selected partners to help us provide you, or the company you work for, products or services, or to fulfill your requests, or with your consent. When selecting our suppliers and partners, we take into account their data handling processes.
Please be aware that in certain circumstances, personal information may be subject to disclosure to government agencies according to judicial proceedings, court order, or legal process. We may also share your personal information to protect the rights or property of Hayche, our business partners, clients, and others when we have reasonable grounds to believe that such rights or property have been or could be affected. 
	
	
Information Security and Accuracy:
We intend to protect your personal information and to maintain its accuracy. Hayche implements reasonable physical, administrative and technical safeguards to help us protect your personal information from unauthorized access, use, and disclosure.  For example, we encrypt certain sensitive personal information such as credit card information when we transmit such information over the Internet.  
	

Retention Period
We will not retain personal information longer than necessary to fulfill the purposes for which it is processed, including the security of our processing complying with legal and regulatory obligations (e.g. audit, accounting, and statutory retention terms), handling disputes, and for the establishment, exercise or defense of legal claims in the countries where we do business. 
	
	
Your Rights
You can request to access, update, or correct, and in certain circumstances, delete your personal information. You also have the right to object to direct marketing. You can access the request process here.
	 
Changes to our Privacy Statements
We may update this Privacy Statement from time to time to reflect changes to our data governance practices. The revised Privacy Statement will be posted here with an updated revision date. We encourage you to check back periodically for any changes or updates. If we make a material change to our Privacy Statement, we will post a notice at the top of this page for 30 days. By continuing to use our websites after such revision takes effect we consider that you have read and understood the changes.
					</pre
            >
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<style scoped>
/* blogs */
.privacy-section {
  margin-bottom: 200px;
}
.privacy-section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: 30px;
  color: #1a1a1a;
}

.privacy {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 30px;
  row-gap: 96px;
  margin-bottom: 79px;
}

.privacy .blog {
  width: 100%;
}

@media (max-width: 768px) {
  .privacy {
    place-items: center;
  }
  .privacy .blog {
    width: 100%;
  }
}

.privacy .blog .detail {
  width: 100%;
  height: 100%;
  padding-right: 25px;
}

.privacy .blog img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  margin-bottom: 35px;
}
.privacy .blog span {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: #1c1c1c;
  margin-bottom: 23px;
}

.privacy-section h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #615d5d;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
  color: black;
}
/* end of blogs */
</style>